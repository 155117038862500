import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";
import { useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";

const BillsPage = ({ userId }) => {
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [webUrl, setWebUrl] = useState("");
  const [showIframe, setShowIframe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handlePayments = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const userDataString = localStorage.getItem("user");
    const userData = JSON.parse(userDataString);
    const userId = userData.unique;
    

    const response = await axios.post(
      `https://backend.inyumba.co.ke/iNyumba/user/payment/initiate-payment/${userId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (
      response.data.success === true &&
      (response.data.response === "You have already paid." ||
        response.data.response === "Already Paid" ||
        response.data.response === "Sorry you have no active reservations" ||
        response.data.response === "Bill details not found!")
    ) {
      toast.error(response.data.response);
      setIsLoading(false);
    } else {
      setWebUrl(response.data.response);
      toast.success("Please Complete Payment");
      setWebUrl(response.data.response);
      setShowIframe(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchBills = async () => {
      try {
        const token = localStorage.getItem("token");
        const userDataString = localStorage.getItem("user");
        const userData = JSON.parse(userDataString);
        const userId = userData.unique;
        const response = await axios.get(
          `https://backend.inyumba.co.ke/iNyumba/admin/bill/view-bill/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setBills(response.data.response);
        setLoading(false);
      } catch (error) {
        toast.error(error);
        // console.error("Error fetching bills:", error);
        setLoading(false);
      }
    };

    fetchBills();
  }, [userId]);

  const handleCloseIframe = () => {
    setShowIframe(false); // Hide the iframe
    setWebUrl(""); // Clear the payment URL
    navigate("/user/home");
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-fulls">
          <div className="bg-white shadow-md rounded-md p-6 w-fulls">
            <div className="flex items-center justify-between mb-4 w-fulls">
              <h2 className="text-lg font-semibold">Bill Details</h2>
              <span className="text-1xl font-semibold mb-8">
                Date: {new Date().toLocaleDateString()}
              </span>
            </div>
            {loading ? (
              <div className="bg-white shadow-md rounded-md p-4">
                <p className="text-lg font-semibold mb-4">Payment</p>
                <p className="text-gray-700 mb-2">Loading...</p>
                <div className="flex flex-col">
                  <button
                    onClick={handlePayments}
                    className={`bg-blue-500 text-white px-4 py-2 rounded ${
                      isLoading
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:bg-blue-600"
                    }`}
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : "Pay for bills"}
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="grid grid-cols-2 gap-2">
                  <div className="flex flex-col">
                    <span className="text-sm font-semibold text-gray-600">
                      Water Bill:
                    </span>
                    <span className="text-lg font-bold text-blue-600">
                      Ksh {bills.WaterBill}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm font-semibold text-gray-600">
                      Rent Bill:
                    </span>
                    <span className="text-lg font-bold text-blue-600">
                      Ksh {bills.RentBill}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm font-semibold text-gray-600">
                      Total Amount:
                    </span>
                    <span className="text-lg font-bold text-blue-600">
                      Ksh {bills.TotalAmount}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <button
                      onClick={handlePayments}
                      className={`bg-blue-500 text-white px-4 py-2 rounded ${
                        isLoading
                          ? "opacity-50 cursor-not-allowed"
                          : "hover:bg-blue-600"
                      }`}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "Pay for bills"}
                    </button>
                  </div>
                </div>
              </>
            )}

            {/* Conditionally render the iframe with close button */}
            {showIframe && webUrl && (
              <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex flex-col items-center justify-center">
                <button
                  onClick={handleCloseIframe}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mb-4"
                >
                  Close
                </button>
                <iframe
                  src={webUrl}
                  className="w-screen h-screen border-none mx-auto"
                  title="Payment Iframe"
                ></iframe>
              </div>
            )}
          </div>
        </div>
      </div>
    </AuthGuard>
  );
};

export default BillsPage;
