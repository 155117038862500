import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthGuard } from "../App";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SpecificRentalPage = () => {
  const [rentalInfo, setRentalInfo] = useState({});
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);
  const token = localStorage.getItem("token");
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const [ratings, setRatings] = useState(0);
  const userId = userData.unique;
  const [amenities, setAmenities] = useState([]);
  const rentalId = localStorage.getItem("specificRentalId");
  const [selectedPhoto, setSelectedPhoto] = useState(null); // State to track selected photo
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false); // State to track if the photo modal is open

  useEffect(() => {
    // Fetch rental information
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/admin/rentals/specific/${rentalId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setRentalInfo(response.data.response);
        // Fetch amenities after setting rentalInfo
        fetchRatings(response.data.response.property_id);
        fetchAmenities(response.data.response.property_id);
      })
      .catch((error) => {
        console.error("Error fetching rental information:", error);
      });

    // Fetch photos
    axios
      .get(`https://backend.inyumba.co.ke/iNyumba/photos/fetch/${rentalId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setPhotos(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching photos:", error);
      });

    // Fetch videos
    axios
      .get(`https://backend.inyumba.co.ke/iNyumba/videos/fetch/${rentalId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setVideos(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching videos:", error);
      });
  }, [rentalId, token]);

  const fetchAmenities = (propertyId) => {
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/amenities/fetch/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setAmenities(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching amenities:", error);
      });
  };

  const handleAddReservation = () => {
    // Add reservation
    axios
      .post(
        `https://backend.inyumba.co.ke/iNyumba/user/reservations/add/${userId}/${rentalId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("You have reserved this rental successfully");
      })
      .catch((error) => {
        console.error("Error adding reservation:", error);
        toast.error("Error adding reservation");
      });
  };

  const handleAddFavourites = () => {
    // Add to favorites
    axios
      .post(
        `https://backend.inyumba.co.ke/iNyumba/favorite/users/${userId}/favorites/${rentalId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("You have added this rental to your favorites");
      })
      .catch((error) => {
        console.error("Error adding to favorites:", error);
        toast.error("Error adding to favorites");
      });
  };

  // Function to fetch ratings
  const fetchRatings = (rentalId) => {
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/admin/comments/average-ratings/${rentalId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setRatings(response.data.response || 0); // Update ratings state variable
      })
      .catch((error) => {
        console.error("Error fetching ratings:", error);
      });
  };

  // Inside the SpecificRentalPage component

  // Function to handle opening the modal with selected photo
  const openPhotoModal = (photo) => {
    setSelectedPhoto(photo);
    setIsPhotoModalOpen(true);
  };

  // Function to close the modal
  const closePhotoModal = () => {
    setSelectedPhoto(null);
    setIsPhotoModalOpen(false);
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        {/* Rental Information */}
        <div className="mb-8">
          <img
            src={rentalInfo.photo}
            alt={`rental photo`}
            className="w-full h-auto object-cover cursor-pointer rounded-lg shadow-lg"
          />
          <div className="mt-4">
            <p className="text-xl font-semibold">
              House Type: {rentalInfo.house_type}
            </p>
            <p className="text-lg">Rent: {rentalInfo.rent_price}</p>
            <p className="text-lg">Description: {rentalInfo.description}</p>
            <p className="text-lg">House Number: {rentalInfo.house_number}</p>
            <p className="text-lg">Vacancy: {rentalInfo.status}</p>
            <p className="text-lg">Ratings: {ratings}/5</p>
          </div>
        </div>
        <h2 className="text-xl font-semibold mb-2">Amenities</h2>
        <div className="flex flex-wrap">
          {amenities.map((amenity, index) => (
            <div key={index} className="bg-gray-100 rounded-md p-2 m-2">
              <p className="text-gray-800">{amenity.amenity_name}</p>
            </div>
          ))}
        </div>
        {/* Display Photos */}
        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-2">Photos</h2>
          <div className="flex flex-wrap">
            {photos.map((photo, index) => (
              <div
                key={index}
                className="w-1/4 p-4"
                onClick={() => openPhotoModal(photo)}
              >
                <img
                  src={photo.photo_url}
                  alt={`rental photo ${index}`}
                  className="w-full h-auto object-cover cursor-pointer rounded-lg shadow-md"
                />
              </div>
            ))}
          </div>
        </div>

        {selectedPhoto && (
          <div
            className="fixed top-0 left-0 z-50 w-full h-full bg-black bg-opacity-75 flex justify-center items-center"
            onClick={closePhotoModal}
          >
            <img
              src={selectedPhoto.photo_url}
              alt={`rental photo`}
              className="max-w-full max-h-full"
            />
            <button
              className="absolute top-4 right-4 text-white"
              onClick={closePhotoModal}
            >
              Close
            </button>
          </div>
        )}
        {/* Display Videos */}
        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-2">Videos</h2>
          <div className="flex flex-wrap">
            {videos.map((video, index) => (
              <div key={index} className="w-1/4 p-4">
                <iframe
                  title={`rental video ${index}`}
                  src={video.video_url}
                  className="w-full h-auto rounded-lg shadow-md"
                  allowFullScreen
                ></iframe>
              </div>
            ))}
          </div>
        </div>
        {/* Add Reservation Button */}
        {rentalInfo.status === "OPEN" && (
          <button
            onClick={handleAddReservation}
            className="mt-8 bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
          >
            Add Reservation
          </button>
        )}
        {/* Add to Favorites Button */}
        {rentalInfo.status === "OPEN" && (
          <button
            onClick={handleAddFavourites}
            className="mt-8 bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
          >
            Add to Favorites
          </button>
        )}
      </div>
    </AuthGuard>
  );
};

export default SpecificRentalPage;
