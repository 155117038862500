import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";

const ViewVacatePage = () => {
  const [vacateData, setVacateData] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userDataString = localStorage.getItem("user");
    const userData = JSON.parse(userDataString);
    const userId = userData.unique;

    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/vacatinghouse/users/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setVacateData(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching vacate data:", error);
      });
  }, []);

  const handleCompleteVacating = (vacatingId) => {
    const token = localStorage.getItem("token");
    const userDataString = localStorage.getItem("user");
    const userData = JSON.parse(userDataString);
    const userId = userData.unique;

    axios
      .put(
        `https://backend.inyumba.co.ke/iNyumba/vacatinghouse/${vacatingId}/user/${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("You have successfully vacated");
        // Refresh vacate data after completion
        fetchVacateData();
      })
      .catch((error) => {
        toast.success("Error completing vacating");
        console.error("Error completing vacating:", error);
      });
  };

  const fetchVacateData = () => {
    const token = localStorage.getItem("token");
    const userDataString = localStorage.getItem("user");
    const userData = JSON.parse(userDataString);
    const userId = userData.unique;

    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/vacatinghouse/users/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setVacateData(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching vacate data:", error);
      });
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-8">View Vacate Page</h1>
        {vacateData.length > 0 ? (
          vacateData.map((vacateRecord, index) => (
            <div
              key={index}
              className="bg-white shadow-lg rounded-lg overflow-hidden mb-8"
            >
              <div className="p-6">
                <p className="text-xl font-semibold mb-4">Vacate Information</p>
                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <p className="text-lg">
                      <span className="font-semibold">
                        Total Amount for Repairs:
                      </span>{" "}
                      {vacateRecord.totalamountrepairs}
                    </p>
                    <p className="text-lg">
                      <span className="font-semibold">Service Fee:</span>{" "}
                      {vacateRecord.servicefee}
                    </p>
                    <p className="text-lg">
                      <span className="font-semibold">Total Fee:</span>{" "}
                      {vacateRecord.totalfee}
                    </p>
                  </div>
                  <div>
                    <p className="text-lg">
                      <span className="font-semibold">Repair Charges:</span>{" "}
                      {vacateRecord.repaircharges}
                    </p>
                    <p className="text-lg">
                      <span className="font-semibold">Completion Status:</span>{" "}
                      {vacateRecord.completestatus}
                    </p>
                    <p className="text-lg">
                      <span className="font-semibold">Your Vacate Status:</span>{" "}
                      {vacateRecord.vacatingstatus}
                    </p>
                  </div>
                </div>
              </div>
              {vacateRecord.vacatingstatus === "ALLOW" && (
                <button
                  onClick={() =>
                    handleCompleteVacating(vacateRecord.vacatinghouse_id)
                  }
                  className="mt-4 mx-auto mb-8 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105"
                >
                  Click to Complete Vacating
                </button>
              )}
            </div>
          ))
        ) : (
          <p>Loading vacate information...</p>
        )}
      </div>
    </AuthGuard>
  );
};

export default ViewVacatePage;
