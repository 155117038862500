import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom"; // Import Navigate for redirection
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify"; // Import ToastContainer
import "./style.css"; // Import Tailwind CSS

import Login from "./Auth/Login";
import OtpVerification from "./Auth/OtpVerification";
import Registration from "./Auth/Registration";
import ForgotPassword from "./Auth/ForgotPassword";
import Logout from "./Auth/Logout";
import Home from "./User/home";
import Profile from "./User/profile";

import { useEffect } from "react";
import WithdrawalsPage from "./User/withdrawals";
import PromoCodePage from "./User/promocode";
import AgencyViewingFee from "./User/agencyviewingfee";
import ReservationsPage from "./User/reservation";
import PreviousReservationsPage from "./User/previousreservations";
import MaintenanceRequestsPage from "./User/maintenance";
import WaterBillPage from "./User/waterbill";
import BillsPage from "./User/bills";
import VacatePage from "./User/addvacate";
import ViewVacatePage from "./User/viewvacate";
import SpecificRentalPage from "./User/specificrental";
import SpecificPropertyPage from "./User/specificproperty";
import FavoritesPage from "./User/favorite";
import AddMaintenanceRequest from "./User/addmaintenance";
import AddComment from "./User/addcomment";
import NotificationsPage from "./User/notifications";

function App() {
  return (
    <Router>
      <Routes>
        {/* Routes accessible whether user is logged in or not */}
        <Route path="/" element={<Login />} />
        <Route path="/OtpVerification" element={<OtpVerification />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/logout" element={<Logout />} />

        {/* Routes accessible only when user is logged in */}

        <Route path="/user/home" element={<Home />} />
        <Route path="/user/profile" element={<Profile />} />
        <Route path="/user/withdrawals" element={<WithdrawalsPage />} />
        <Route path="/user/promocode" element={<PromoCodePage />} />
        <Route path="/user/agencyviewingfee" element={<AgencyViewingFee />} />
        <Route path="/user/reservation" element={<ReservationsPage />} />
        <Route path="/user/bills" element={<BillsPage />} />
        <Route path="/user/addvacate" element={<VacatePage />} />
        <Route path="/user/viewvacate" element={<ViewVacatePage />} />
        <Route path="/user/specificrental" element={<SpecificRentalPage />} />
        <Route path="/user/favorite" element={<FavoritesPage />} />
        <Route path="/user/addcomment" element={<AddComment />} />
        <Route
          path="/user/addmaintenance"
          element={<AddMaintenanceRequest />}
        />
        <Route
          path="/user/specificproperty"
          element={<SpecificPropertyPage />}
        />

        <Route
          path="/user/maintenancerequest"
          element={<MaintenanceRequestsPage />}
        />
        <Route path="/user/waterbill" element={<WaterBillPage />} />
        <Route
          path="/user/previousreservation"
          element={<PreviousReservationsPage />}
        />

        <Route path="/user/notifications" element={<NotificationsPage />} />

        {/* <Route path="*" element={<Navigate to="/" />} /> */}
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;

export function AuthGuard({ children }) {
  const navigate = useNavigate();
  useEffect(() => {
    // Retrieve the JSON string from localStorage
    const userDataString = localStorage.getItem("user");

    try {
      // Parse the JSON string to convert it into a JavaScript object
      const userData = JSON.parse(userDataString);
      // Access the 'unique' property from the 'user' object
      const userId = userData.unique;
      console.log(userId, "user");
      if (!userId) {
        navigate("/");
      }
    } catch (error) {
      navigate("/");
    }
  }, []);

  return <>{children}</>;
}

export function TokenGuard({ children }) {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
  }, []);

  return <>{children}</>;
}
