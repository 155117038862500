import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { AuthGuard } from "../App";

const PromoCodePage = () => {
  const [promoCode, setPromoCode] = useState("");
  const navigate = useNavigate();
  // Outside of the handleSubmit function

  const handleSubmit = async () => {
    try {
      let response;
      const token = localStorage.getItem("token");
      const userDataString = localStorage.getItem("user");
      const userData = JSON.parse(userDataString);
      const userId = userData.unique;

      response = await axios.post(
        `https://backend.inyumba.co.ke/iNyumba/promocoderetrieval/${userId}`,
        { code: promoCode },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success === true) {
        // Empty the input field
        setPromoCode("");
        toast.success("Promo code added successfully");
      } else {
        toast.error("Failed to add promo code: " + response.data.message);
      }
    } catch (error) {
      toast.error("Error submitting code or code is expired");
      console.error("Error submitting promo code:", error);
    }
  };

  return (
    <AuthGuard>
      <div className="container mx-auto mt-20 flex flex-col items-center">
        <h1 className="text-3xl font-semibold mb-8">Promo Code</h1>
        <span className="text-1xl font-semibold mb-8">
          Date: {new Date().toLocaleDateString()}
        </span>
        <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
          <input
            type="text"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            className="border border-gray-300 p-3 rounded-md w-full md:w-80"
            placeholder="Enter promo code"
          />
          <button
            onClick={handleSubmit}
            className="bg-blue-500 text-white px-6 py-3 rounded-md transition duration-300 hover:bg-blue-600 focus:outline-none"
          >
            Submit
          </button>
        </div>
      </div>
      <ToastContainer />
    </AuthGuard>
  );
};

export default PromoCodePage;
