import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faSignOutAlt,
  faHeart,
  faCreditCard,
  faWallet,
  faBell,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import { AuthGuard } from "../App";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Dropdown,
  DropdownDivider,
  DropdownHeader,
  DropdownItem,
  Navbar,
  NavbarBrand,
  NavbarCollapse,
  NavbarLink,
  NavbarToggle,
} from "flowbite-react";

const Home = () => {
  const [properties, setProperties] = useState([]);
  const [locations, setLocations] = useState([]);
  const [houseTypes, setHouseTypes] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedHouseType, setSelectedHouseType] = useState("");
  const [rentals, setRentals] = useState([]);
  const userDataString = localStorage.getItem("user");
  const [propertyRatings, setPropertyRatings] = useState({});
  const [rentalRatings, setRentalRatings] = useState({});
  const [notificationsCount, setNotificationsCount] = useState(0);
  const userData = JSON.parse(userDataString);
  const userId = userData.unique;
  const [webUrl, setWebUrl] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        // Fetch locations
        const locationResponse = await axios.get(
          "https://backend.inyumba.co.ke/iNyumba/viewlocations",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setLocations(locationResponse.data.response);

        // Fetch house types
        const houseTypeResponse = await axios.get(
          "https://backend.inyumba.co.ke/iNyumba/viewhousetype",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setHouseTypes(houseTypeResponse.data.response);

        try {
          const token = localStorage.getItem("token");
          // Fetch rentals
          const rentalResponse = await axios.get(
            "https://backend.inyumba.co.ke/iNyumba/admin/rentals/filterrental",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setRentals(rentalResponse.data.response);
        } catch (error) {
          console.error("Error fetching rentals:", error);
        }

        // Fetch properties
        const propertyResponse = await axios.get(
          "https://backend.inyumba.co.ke/iNyumba/admin/properties/records",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setProperties(propertyResponse.data.response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      // Fetch notifications count
      const notificationsResponse = await axios.get(
        "https://backend.inyumba.co.ke/iNyumba/inappnotifications/viewallnotifications",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setNotificationsCount(notificationsResponse.data.response.length);
    };

    fetchData();
  }, []);

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const handleHouseTypeChange = (event) => {
    setSelectedHouseType(event.target.value);
  };

  const handleResetFilters = () => {
    setSelectedLocation("");
    setSelectedHouseType("");
  };

  const handleFavoritesClick = () => {
    // Action to perform when Favorites is clicked
    navigate("/user/favorite");
  };

  const handlePropertyClick = (PropertyId) => {
    // Handle property click
    localStorage.setItem("specificPropertyId", PropertyId);
    navigate("/user/specificproperty");
  };

  const handleRentalClick = (RentalId) => {
    // Handle rental click
    localStorage.setItem("specificRentalId", RentalId);
    navigate("/user/specificrental");
  };

  const handleBillPaymentsClick = () => {
    // Action to perform when Bill Payments is clicked
    navigate("/user/bills");
  };

  const handleVacatingClick = () => {
    // Action to perform when Vacating is clicked
    navigate("/user/viewvacate");
  };

  const handleWaterbillClick = () => {
    // Action to perform when Waterbill is clicked
    navigate("/user/waterbill");
  };

  const handleReservationClick = () => {
    // Action to perform when Reservation is clicked
    navigate("/user/reservation");
  };

  const handleMaintenanceRequestClick = () => {
    // Action to perform when MaintenanceRequest is clicked
    navigate("/user/maintenancerequest");
  };

  const handlePreviousReservationsClick = () => {
    // Action to perform when PreviousReservations is clicked
    navigate("/user/previousreservation");
  };

  const handleProfileClick = () => {
    navigate("/user/profile");
  };

  const handleNotificationsClick = () => {
    // Redirect to profile screen
    navigate("/user/notifications");
  };

  const handleLogoutClick = () => {
    navigate("/logout");
  };

  const handleWithdrawalsClick = () => {
    // Action to perform when Withdrawals is clicked
    navigate("/user/withdrawals");
  };

  const handleAgencyFeeClick = () => {
    // Action to perform when Agency Fee is clicked
    navigate("/user/agencyviewingfee");
  };

  const handlePromoCodeClick = () => {
    // Action to perform when Promo Code is clicked
    navigate("/user/promocode");
  };

  const handleApplyFilters = () => {
    // Check if either selectedLocation or selectedHouseType is present
    if (selectedLocation || selectedHouseType) {
      // Fetch rentals based on filters
      const queryParams = {};
      if (selectedLocation) {
        queryParams.location = selectedLocation;
      }
      if (selectedHouseType) {
        queryParams.house_type = selectedHouseType;
      }

      const token = localStorage.getItem("token");
      axios
        .get(
          "https://backend.inyumba.co.ke/iNyumba/admin/rentals/filterrental",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: queryParams,
          }
        )
        .then((response) => {
          setRentals(response.data.response); // Assuming response contains filtered rentals
        })
        .catch((error) => {
          console.error("Error fetching filtered rentals:", error);
        });
    } else {
      // Fetch all rentals without any filters
      const token = localStorage.getItem("token");
      axios
        .get(
          "https://backend.inyumba.co.ke/iNyumba/admin/rentals/filterrental",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setRentals(response.data.response); // Assuming response contains all rentals without filters
        })
        .catch((error) => {
          console.error("Error fetching all rentals:", error);
        });
    }
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <div className="container mx-auto px-4 py-8">
          {/* Sticky Navbar */}

          <Navbar fluid rounded>
            <NavbarBrand href="#">
              <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
                iNyumba
              </span>
            </NavbarBrand>
            <div
              className="relative cursor-pointer"
              onClick={handleNotificationsClick}
            >
              <FontAwesomeIcon icon={faBell} className="text-xl mr-4" />
              {notificationsCount > 0 && (
                <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full text-xs w-5 h-5 flex items-center justify-center">
                  {notificationsCount}
                </span>
              )}
            </div>
            <div className="flex md:order-2">
              <Dropdown
                arrowIcon={false}
                inline
                label={<span className="mr-3 h-6 sm:h-9">Profile</span>}
              >
                <DropdownHeader></DropdownHeader>
                <DropdownItem onClick={handleProfileClick}>
                  Profile
                </DropdownItem>
                <DropdownItem onClick={handleFavoritesClick}>
                  Favorites
                </DropdownItem>
                <DropdownItem onClick={handleWaterbillClick}>
                  Waterbill
                </DropdownItem>
                <DropdownItem onClick={handleReservationClick}>
                  Reservation
                </DropdownItem>
                <DropdownItem onClick={handleMaintenanceRequestClick}>
                  MaintenanceRequest
                </DropdownItem>
                <DropdownItem onClick={handleVacatingClick}>
                  Vacating
                </DropdownItem>
                <DropdownItem onClick={handlePreviousReservationsClick}>
                  PreviousReservations
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem onClick={handleLogoutClick}>
                  Sign out
                </DropdownItem>
              </Dropdown>
              <NavbarToggle />
            </div>
            <NavbarCollapse>
              <NavbarLink href="#" active>
                Home
              </NavbarLink>
              <NavbarLink onClick={handleBillPaymentsClick}>
                Bill Payments
              </NavbarLink>
              <NavbarLink onClick={handleWithdrawalsClick}>
                Withdrawals
              </NavbarLink>
              <NavbarLink onClick={handleAgencyFeeClick}>Agency Fee</NavbarLink>
              <NavbarLink onClick={handlePromoCodeClick}>Promo Code</NavbarLink>
            </NavbarCollapse>
          </Navbar>

          {/* Centered heading */}
          <div className="flex justify-center">
            <p className="text-2xl font-semibold mb-4">Properties</p>
          </div>
          <div className="flex flex-nowrap overflow-x-auto">
            {properties.map((property) => (
              <div
                key={property.id}
                className="flex-shrink-0 flex flex-col bg-white shadow-md rounded-md p-6 m-4 cursor-pointer"
                onClick={() => handlePropertyClick(property.property_id)}
              >
                <img
                  src={property.photo}
                  alt={property.title}
                  className="w-full h-32 object-cover mb-2 rounded"
                />
                <div className="text-base font-semibold mb-1">
                  {property.title}
                </div>
                <div className="text-gray-600 mb-1">{property.description}</div>
                <div className="text-gray-600 mb-1">
                  County: {property.county}
                </div>
                <div className="text-gray-600 mb-1">
                  Location: {property.location}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Centered heading */}
        <div className="flex justify-center">
          <p className="text-2xl font-semibold mb-4">Rentals</p>
        </div>

        {/* Filter Section */}
        <div className="mt-8 mb-8 flex flex-col lg:flex-row justify-center items-center lg:space-x-4 lg:space-y-0 space-y-4">
          {/* {" "} */}
          <select
            value={selectedLocation}
            onChange={handleLocationChange}
            className="mr-4 p-2 border border-gray-400 rounded"
            style={{ color: "black" }}
          >
            <option key="default" value="">
              Select Location
            </option>
            {locations.map((location, index) => (
              <option key={index} value={location}>
                {location}
              </option>
            ))}
          </select>
          <select
            value={selectedHouseType}
            onChange={handleHouseTypeChange}
            className="mr-4 p-2 border border-gray-400 rounded"
            style={{ color: "black" }}
          >
            <option key="default" value="">
              Select House Type
            </option>
            {houseTypes.map((houseType, index) => (
              <option key={index} value={houseType} style={{ color: "black" }}>
                {houseType}
              </option>
            ))}
          </select>
          <div className="flex justify-center">
            <button
              onClick={handleResetFilters}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-4"
            >
              Reset
            </button>
            <button
              onClick={handleApplyFilters}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Apply
            </button>
          </div>
        </div>

        <div className="flex flex-nowrap overflow-x-auto">
          {rentals.map((rental) => (
            <div
              key={rental.rental_id}
              className="flex-shrink-0 flex flex-col bg-white shadow-md rounded-md p-6 m-4 cursor-pointer"
              onClick={() => handleRentalClick(rental.rental_id)}
            >
              <img
                src={rental.photo}
                alt={rental.property_name}
                className="w-full h-32 object-cover mb-2 rounded"
              />
              <div className="text-base font-semibold mb-1">
                {rental.property_name}
              </div>
              <div className="text-gray-600 mb-1">{rental.description}</div>
              <div className="text-gray-600 mb-1">
                House Type: {rental.house_type}
              </div>
              <div className="text-gray-600 mb-1">
                Rent Price: {rental.rent_price}
              </div>
              <div className="text-gray-600 mb-1">
                Location: {rental.location}
              </div>
            </div>
          ))}
        </div>
      </div>
    </AuthGuard>
  );
};

export default Home;
