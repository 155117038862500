import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthGuard } from "../App";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MaintenanceRequestsPage = () => {
  const [maintenanceRequests, setMaintenanceRequests] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const userId = userData.unique;

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      // Fetch maintenance requests
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/maintenance/users/${userId}/maintenance-requests`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setMaintenanceRequests(response.data.response);
        })
        .catch((error) => {
          console.error("Error fetching maintenance requests:", error);
        });
    }
  }, []);

  const handleDeleteRequest = (maintenanceRequestId) => {
    axios
      .delete(
        `https://backend.inyumba.co.ke/iNyumba/maintenance/maintenance-requests/${maintenanceRequestId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // Refresh maintenance requests after successful deletion
        const updatedRequests = maintenanceRequests.filter(
          (request) => request.id !== maintenanceRequestId
        );
        setMaintenanceRequests(updatedRequests);
        toast.success("Successful Deletion. Kindly refresh");
      })
      .catch((error) => {
        console.error("Error deleting maintenance request:", error);
        toast.error("Error deleting maintenance request");
      });
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Maintenance Requests</h1>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <span className="text-1xl font-semibold mb-8">
            Date: {new Date().toLocaleDateString()}
          </span>
          {maintenanceRequests.map((request) => (
            <div key={request.id} className="bg-white shadow-md rounded-md p-4">
              <p>
                <strong>Request:</strong> {request.request_content}
              </p>
              <p>
                <strong>Status:</strong> {request.status}
              </p>
              <button
                onClick={() => handleDeleteRequest(request.request_id)}
                className="mt-2 bg-red-500 text-white px-4 py-2 rounded"
              >
                Delete
              </button>
            </div>
          ))}
        </div>
      </div>
    </AuthGuard>
  );
};

export default MaintenanceRequestsPage;
